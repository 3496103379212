import { configureStore, createSlice } from '@reduxjs/toolkit'
import {
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE,
    persistReducer,
    persistStore,
} from 'redux-persist'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import { combineReducers } from 'redux';

import currentWorkoutReducer from '../features/excercises/currentWorkoutSlice'
import historyReducer from '../features/history/historySlice'
import createCompressor from 'redux-persist-transform-compress'
import { KvStorageSingleton } from './kvstorage'


const initialState = {
    state: 'loading',
    error: undefined,
};

const commonSlice = createSlice({
    name: 'common',
    initialState: initialState,
    reducers: {
        error(state, action) {
            const payload = action.payload;
            state.error = payload.error;
        },
        loaded(state, action) {
            state.state = 'loaded';
            state.error = undefined;
        },
    }
});


const logMiddleware = storeAPI => next => action => {
    /*
    console.log('action: ', action)
    console.log('state: ', storeAPI.getState())
    */

    if (action.type === REHYDRATE) {
        if (action.err !== undefined) {
            return next(commonSlice.actions.error({error: 'loading error'}))
        }

        /*
        action = {
            ...action,
            payload: {
                ...action.payload,
                common: {
                    state: 'loaded',
                    error: undefined,
                },
            },
        }
        */
    }
    // Otherwise, it's a normal action - send it onwards
    const result = next(action);
    next(commonSlice.actions.loaded());
    return result;
}

const compressor = createCompressor()

const persistConfig = {
    key: 'root',
    version: 1,
    storage: KvStorageSingleton,
    stateReconciler: hardSet,
    debug: true,
    throttle: 1000,
    transforms: [compressor],
    blacklist: ['common'],
};

const reducers = combineReducers({
    common: commonSlice.reducer,
    currentWorkout: currentWorkoutReducer,
    history: historyReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(logMiddleware)
    },
});

export const persistor = persistStore(store);
