import {
  NavLink,
  Outlet
} from 'react-router-dom'
import {
  useEffect, useState,
}  from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import { useSelector } from 'react-redux'

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';

import { KvStorageSingleton } from './store/kvstorage'

function CenterSpinner({children}) {
  return <div
        className="d-flex align-items-center justify-content-center text-center high"
      >
          <Spinner /><br />
          {children}
      </div>
  ;
}

function CenterBlock({children}) {
  return <div
        className="d-flex align-items-center justify-content-center text-center high"
      >
          {children}
      </div>
  ;
}


function App() {
  const common = useSelector((state) => { return state.common });
  const [loggedIn, setLoggedIn] = useState(KvStorageSingleton.isInitialized());

  useEffect(() => {
    window.addEventListener(
     'message',
      (event) => {
        if (event.origin === KvStorageSingleton.origin && event.data.storage_key) {
          KvStorageSingleton.setKey(event.data.storage_key);
          setLoggedIn(KvStorageSingleton.isInitialized());
          if (KvStorageSingleton.isInitialized()) {
            window.location.reload();
          }
        }
      },
      false,
    );
  }, []);

  const openOauthYdx = () => {
    const origin = window.location.origin;
    window.open(`https://oauth.yandex.ru/authorize?response_type=code&force_confirm=yes&client_id=0986d0ab606d4af09ee1bd3a2313493c&redirect_uri=${KvStorageSingleton.authPageYdx}&state=${origin}`);
    return false;
  }

  if (!loggedIn) {
    return (
      <CenterBlock>
        <Button onClick={openOauthYdx}>Login with yandex</Button>
      </CenterBlock>
    );
  } else if (common.error !== undefined) {
    return (
      <CenterBlock>
        Error while loading from server. Please refresh page.
      </CenterBlock>
    );
  } else if (common.state === 'loading') {
    return (
        <CenterSpinner>
          Loading from cloud...
        </CenterSpinner>
    );
  }

  return (
    <Container style={{maxWidth: '768px'}}>
      <Row>
        <Col>
          <Navbar>
            <Navbar.Brand href="#"><img src="/logo-white.svg" alt="Lifts" height="48" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav className="me-auto">
                <Nav.Link as={NavLink} to="/">Home</Nav.Link>
                <Nav.Link as={NavLink} to="/history">History</Nav.Link>
                <Nav.Link as={NavLink} to="/analytics">Analytics</Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link href="#" onClick={() => {KvStorageSingleton.resetKey(); window.location.reload()} }>Logout</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Col>
      </Row>
      <Outlet />
    </Container>
  );
}

export default App;

// vim: set ts=2 sw=2
